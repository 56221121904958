.column {
  &:not(:last-child) {
    border-right: 1px solid #d1cece;
  }
  text-align: center;
  &Header {
    font-size: 13px;
    font-weight: 700;
    background-color: #efefff;
    padding: .4em 0;
    margin-bottom: .4em;
  }
}
.timespan {
  padding: .2em 0;
  &Container {
    user-select: none;
  }
  &Selectable {
    cursor: pointer;
  }
}

//:global(.selection-area) {
//  background: rgba(46, 115, 252, 0.11);
//  border: 2px solid rgba(98, 155, 255, 0.81);
//  border-radius: 0.1em;
//}
