.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &Actions {
    display: flex;
    gap: 10px;
  }

}
