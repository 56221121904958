.entry {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 16px;
  &Wrapper {
    border-radius: 12px;
    box-shadow: 1px 1px 12px 2px rgba(0, 0, 139, 0.09);
    box-sizing: border-box;
    padding: 16px 34px;
  }
  &Row {
    height: 100%;
  }
  &Logo {
    margin: 0 auto 26px;
    max-width: 80%;
  }
  &Title {
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
  }
}
