.wrapper {
  width: 100%;
  margin-bottom: .5em;
  &Horizontal {
    display: flex;
    align-items: center;
    .wrapperLabel {
      margin-right: 1em;
    }
  }
  &Label {
    position: relative;
  }
  &Data {
    margin-top: 0;
  }
}

.extraLabel {
  position: absolute;
  top: 0;
  right: 0;
}
