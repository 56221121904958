

.body {
  margin-top: 2em;
  &Row {
    &:hover {
      cursor: pointer;
    }
  }
}
