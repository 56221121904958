.error {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &Icon {
    font-size: 150px;
  }
  &Title {
    font-size: 146px;
    font-weight: 700;
  }
  &Subtitle {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 16px;
  }
}
