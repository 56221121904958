img {
  width: 100%;
}
.pre {
  font: inherit !important;
  white-space: pre-wrap;
}
.overflow-scroll {
  overflow-y: scroll;
}
