.upload {
  &Icon {
    font-size: 40px;
    margin-bottom: 0;
  }
  &Text {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
  }
  &Subtitle {
    font-style: italic;
  }
  &ExistedImage {
    width: 15%;
    margin: 0 auto;
  }
}
