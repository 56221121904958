.btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
  &Blue {
    background-color: #c3d4fa;
    border-color: #053ab2;
    .text {
      color: #053ab2;
    }
    .icon {
      color: #053ab2;
    }
  }
  &Red {
    background-color: #fac3c3;
    border-color: #b20505;
    .text {
      color: #b20505;
    }
    .icon {
      color: #b20505;
    }
  }
  &Green {
    background-color: #cafac3;
    border-color: #1cb205;
    .text {
      color: #25b205;
    }
    .icon {
      color: #25b205;
    }
  }
}

.icon {
  font-size: 20px;
  margin-bottom: 4px;
}
.text {
  font-size: 16px;
}
