@import "src/styles/params";

.tutor {
  &Photo {
    width: 50%;
    overflow: hidden;
    margin: 0 auto;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  &Actions {
    margin-top: 1em;
  }

  &TrimmedText {
    overflow: hidden;
    span {
      white-space: nowrap;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
