.logo {
  box-sizing: border-box;
  padding: 16px 32px;
}

.sider {
  height: 100vh;
  overflow: scroll;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  &User {
    box-sizing: border-box;
    padding: 0 20px;
    &Name {
      font-weight: 900;
    }
  }
  &Wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
  }
}
.content {
  margin: 18px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 18px;
  min-height: 83vh;
}
.footer {
  text-align: center;
}

.layout {
  margin-left: 0;
  @media (min-width: 992px) {
    margin-left: 300px;
  }
}
